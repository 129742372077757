<template>
  <v-card flat>
    <v-card-text>
      <v-form>
        <v-text-field label="Title" v-model="link.title" counter="255"></v-text-field>
        <v-text-field label="URL" v-model="link.url" counter="2048"></v-text-field>
        <v-text-field label="Human Link Text" prefix="https://affx.eu/" v-model="link.human" counter="768"
                      :rules="textRules"></v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "LinkCreateCard",
  props: {
    link: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      textRules: [
        v => {
          const matcher = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'ig')
          return matcher.test(v) || 'only lower- and uppercase letters and numbers and - allowed'
        },
        v => {
          if (v) {
            if ((v.length !== 0) && (v.length > 768)) {
              return 'The human readable field shall not exceed 768 characters'
            } else {
              return true
            }
          } else {
            return true
          }
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
