<template>
  <div>
    <v-btn @click="openCreateDialog" block text>Create New Link</v-btn>
    <UrlInput :input="currentUrl" @submit="onUrlInputSubmit($event)"></UrlInput>
    <v-card v-for="link in links" v-bind:key="link.id">
      <v-card-title v-if="link && link.title">{{ link.title }}</v-card-title>
      <v-card-subtitle>{{ dateFormat(link.created_at) }}</v-card-subtitle>
      <v-card-text>{{ link.url }}</v-card-text>
      <v-card-text v-if="link && link.counter && link.counter.count">{{ link.counter.count }}</v-card-text>
      <v-card-text class="blue--text lighten-4">
        https://affx.eu/{{ link.short }}
      </v-card-text>
      <v-card-actions>
        <v-tooltip open-delay="500" top>
          <template #activator="{on, attrs}">
            <v-btn icon color="blue" @click.stop="openEditDialog(link)" v-bind="attrs" v-on="on">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>show or edit details</span>
        </v-tooltip>
        <v-tooltip open-delay="500" top>
          <template #activator="{on, attrs}">
            <v-btn text color="blue" v-bind="attrs" v-on="on">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>copy</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar.open" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
    <v-dialog v-model="editDialog" fullscreen>
      <v-card>
        <v-toolbar outlined>
          <v-btn icon @click="closeEditDialog">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="saveEditDialog(true)">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <LinkDetailCard :link="selectedLink"></LinkDetailCard>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createDialog" fullscreen>
      <v-card>
        <v-toolbar outlined>
          <v-btn icon @click="closeCreateDialog(false)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Create</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="saveCreateDialog">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <LinkCreateCard :link="createModel"></LinkCreateCard>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "@/router";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import langJSON from 'dayjs/locale.json'
import LinkDetailCard from "@/components/LinkDetailCard";
import UrlInput from "@/components/UrlInput";
import LinkCreateCard from "@/components/LinkCreateCard";

dayjs.extend(advancedFormat)
let lang = navigator.language.toLocaleLowerCase()
if (lang && langJSON) {
  let found = false
  if (langJSON.some(it => it.key === lang)) {
    found = true
  }
  if (!found) {
    lang = 'en'
  }
}

if (lang && lang === 'en-us') {
  lang = 'en'
}
const locale = require(`dayjs/locale/${lang}`)

export default {
  name: "ManageIndex",
  components: {LinkCreateCard, UrlInput, LinkDetailCard},
  data() {
    return {
      currentUrl: '',

      snackbar: {
        open: false,
        message: '',
        color: undefined,
        timeout: 5000,
      },

      editDialog: false,
      selectedLink: {},

      createDialog: false,
      createModel: {
        url: undefined,
        title: undefined,
        text: undefined
      },
    }
  },
  computed: {
    links() {
      return this.$store.$db().model('link').query().orderBy('created_at', 'desc').withAll().all()
    }
  },
  methods: {
    dateFormat: (date, format = 'DD. MMMM YYYY - HH:mm') => {
      return dayjs(date).locale(locale).format(format)
    },
    openEditDialog(link) {
      this.selectedLink = link
      this.editDialog = true
    },
    openCreateDialog(clean = true) {
      this.createDialog = true
      if (clean) {
        this.createModel = {
          url: undefined,
          title: undefined,
          text: undefined
        }
      }
    },
    closeEditDialog() {
      this.selectedLink = {}
      this.editDialog = false
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    saveCreateDialog() {
      this.$store.$db().model('link').api().post(`${process.env.VUE_APP_API_ROOT}/link/`, this.createModel)
          .then(() => {
            this.showSnackbar(
                'Created',
                'green'
            )
            this.$store.$db().model('count').api().get(`${process.env.VUE_APP_API_ROOT}/count/`)
          }).catch(err => {
        this.showSnackbar(
            err.response.data.error,
            'red',
            15000
        )
      })
    },
    saveEditDialog(close) {
      if (this.selectedLink.human === '') {
        this.selectedLink.human = null
      }
      if (this.selectedLink.title === '') {
        this.selectedLink.title = null
      }
      this.$store.$db().model('link').api().put(`${process.env.VUE_APP_API_ROOT}/link/${this.selectedLink.id}`, this.selectedLink)
          .then(() => {
            this.showSnackbar(
                'Saved',
                'green'
            )
            if (close) {
              this.editDialog = false
            }
          }).catch(err => {
        this.showSnackbar(
            err.response.data.error,
            'red',
            15000
        )
      })
    },
    showSnackbar(message, color, duration = 5000) {
      this.snackbar.color = color
      this.snackbar.message = message
      this.snackbar.open = true
      this.snackbar.timeout = duration
    },
    onUrlInputSubmit(url) {
      this.$store.$db().model('link').api().post(`${process.env.VUE_APP_API_ROOT}/link/`, {
        url: url
      }).then(rsp => {
        this.showSnackbar(
            'Created',
            'green'
        )
        this.currentUrl = `https://affx.eu/${rsp.response.data.id.toString(36)}`
        this.$store.$db().model('count').api().get(`${process.env.VUE_APP_API_ROOT}/count/`)
      }).catch(err => {
        this.showSnackbar(
            err.response.data.error,
            'red',
            15000
        )
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    if (router.app.$keycloak.authenticated) {
      router.app.$store.$db().model('link').api()
          .get(`${process.env.VUE_APP_API_ROOT}/link/`)
          .then(() => {
            let errIDs = []
            const urls = JSON.parse(localStorage.getItem('urls'))
            if (urls && urls.length > 0) {
              for (const url of urls) {
                const data = {
                  id: parseInt(url.short, 36),
                  url: url.url,
                }
                router.app.$store.$db().model('link')
                    .api().put(`${process.env.VUE_APP_API_ROOT}/link/${data.id}`, data)
                    .catch(err => {
                      this.snackbar.message += err.response.data.error + "\n"
                      this.snackbar.open = true
                      errIDs.push(data.id)
                    })
              }
              if (errIDs.length > 0) {
                const errSet = new Set(errIDs)
                const newUrls = urls.filter(obj => errSet.has(obj.id))
                localStorage.setItem('urls', JSON.stringify(newUrls))
              } else {
                localStorage.removeItem('urls')
              }
            }
            // get link count of signed in user
            router.app.$store.$db().model('count').api()
                .get(`${process.env.VUE_APP_API_ROOT}/count/`)
                .then(() => {
                  next()
                }).catch(err => {
              console.error('getting link counters:', err)
              next()
            })

          })
          .catch(err => {
            console.error('getting link:', err)
            next()
          })
    } else {
      router.replace('/')
    }
  }
}
</script>

<style scoped>

</style>
